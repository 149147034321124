import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/Seo';
import { Container, Card, Row, Col } from 'reactstrap';
import ServiceInquiryForm from '../components/Forms/ServiceInquiryForm';
import SideMenuLayout from '../components/layouts/SideMenuLayout';
import ReactPlayer from 'react-player';
import Layout from '../components/layouts/Layout';

const Service = ({ data, pageContext: { menuItems } }) => {
  const serviceData = data.allStrapiServices?.nodes?.[0];
  const serviceMessage = serviceData.message.replace(/&nbsp;/g, ' ')

  return (
    <Layout navColor="white" margined={true}>
      <SEO
        title={`Услуга ${serviceData?.title}`}
        description={serviceData?.sub_title}
        image={
          serviceData?.images?.length > 0 &&
          serviceData.images[0].localFile.publicURL
        }
      />
      <Container fluid="md" className="py-5">
        <h1 className="mb-4">{serviceData.title}</h1>
        <Card className="p-4 service">
          <h3 className="mb-4">{serviceData.sub_title}</h3>
          {serviceData.description && <div className='mb-3' dangerouslySetInnerHTML={{ __html: serviceData.description }} />}
          {serviceData.sections.map((section, i) => {
            const description = section?.description?.replace(/&nbsp;/g, ' ')

            return <div key={`term-section-${i}`} className="mb-5">
              <h3 className="mb-4">{section.title}</h3>
              <div className="info-page__video-text ">
                {section.video_url && <div className="info-page__video-text__video text-center mx-2 mb-3 mb-md-2 mt-2 mr-sm-5">
                  <ReactPlayer
                    width="auto"
                    url={`${section.video_url} `}
                    controls={false}
                  />
                </div>}
                {section?.images?.length > 0 && <div style={{ overflowX: 'auto', width: section?.description ? "600px" : "100%" }} className="info-page__image-text__image text-center mx-2 mb-3 mb-md-2 mt-2 mr-sm-5 ">

                  <img style={{ width: "100%", height: "100%" }} src={`${process.env.GATSBY_BACKEND}${section.images[0].url}`} alt="" />
                </div>}
                <div className="info-page__video-text__text" dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          })}


          <Row className="pt-0">
            <Col xs={12} lg={8}>
              <p className="service-message">{serviceMessage}</p>
            </Col>
            <Col sm={12} md={12}>
              <ServiceInquiryForm
                serviceType={serviceData.nodeType}
                serviceId={serviceData.strapiId}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </Layout>
  );
};

//         image {
//           localFile {
//             childImageSharp {
//       gatsbyImageData(transformOptions: { fit: COVER }, layout: FULL_WIDTH)
//     }
//     publicURL
//   }
//   url
// }
export const query = graphql`
  query Services($id: Int, $type: String) {
    allStrapiServices(filter: { strapiId: { eq: $id }, nodeType: { eq: $type } }) {
      nodes {
        id
        strapiId
        title
        nodeType
        message
        sections {
          title
          description
          video_url
          images {
              localFile {
                childImageSharp {
                  gatsbyImageData(transformOptions: { fit: FILL }, placeholder: DOMINANT_COLOR)
                }
                publicURL
              }
              url
           }
        }
        order_index
        sub_title
      }
    }
  }
`;

export default Service;


// {
//   serviceData?.sections?.length > 0 && serviceData.sections.map((section, i) => (
//     <div key={`term-section-${i}`} className="mb-5">
//       <h3 className="mb-4">{section.title}</h3>
//       <div className="info-page__video-text ">
//         {section.video_url && <div style={{ display: "contents", width: "100%" }} className=" text-center mx-2 mb-3 mb-md-2 mt-2 mr-sm-5">
//           <ReactPlayer
//             width="auto"
//             url={`${section.video_url} `}
//             controls={false}
//           />
//         </div>}
//         {section?.images?.length > 0 && <div style={{ maxHeight: "500px", overflow: "hidden" }} className=""><div style={{ width: "100%" }} className=" text-center mx-2 mb-3 mb-md-2 mt-2 mr-sm-5 ">
//           <img style={{ width: "100%", height: "100%" }} src={`${process.env.GATSBY_BACKEND}${section.images[0].url}`} alt="" />
//         </div></div>}
//         <div className={`${(section.video_url || section?.images?.length > 0) && "mt-3"}`} dangerouslySetInnerHTML={{ __html: section.description }} />

//       </div>



//     </div>
//   ))
// }