import React from 'react';
import Layout from './Layout';
import { Container, Row, Col } from 'reactstrap';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SideMenuLayout = ({ children, menuItems = [] }) => {
  const location = useLocation();
  const url = location ? location?.pathname.split('/') : '/';

  return (
    <Layout navColor="white" margined={true}>
      <Container style={{ minHeight: '500px' }} fluid="xl">
        <Row>
          <Col
            md={3}
            style={{ borderRight: '1px solid #E4E4E4' }}
            className="d-none d-md-block p-md-1 p-0"
          >
            <ul className={`user-nav dark`}>
              {menuItems.map((item) => (
                <Link
                  to={item.url}
                  key={`sideMenu-${item.label}`}
                  className={`user-nav_item d-flex  px-1 py-3 ${
                    url.includes(item.url) ? ' active' : ''
                  }`}
                >
                  <div className="user-nav_item_content d-flex">
                    {item.image && item.image.localFile && (
                      <div className="pr-1">
                        <GatsbyImage
                          image={item.image.localFile.childImageSharp.gatsbyImageData}
                          alt={item.label}
                        />
                      </div>
                    )}
                    {item.label}
                  </div>
                </Link>
              ))}
            </ul>
          </Col>
          <Col xs={12} md={9} className="px-md-5 py-md-2">
            {children}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default SideMenuLayout;
